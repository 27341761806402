@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --background: #ededed;
  --foreground: #ededed;
  --primary: #0066b3;
  --warning: #fdb461e8;
  --success: #82b85cf5;
  --hover: #3284c2;
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: #ededed;
    --foreground: #ededed;
    --primary: #0066b3;
    --warning: #fdb461e8;
    --success: #82b85cf5;
    --hover: #3284c2;
  }
}

body {
  color: var(--foreground);
  background: var(--background);
  overflow: auto;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

::-webkit-scrollbar {
  background-color: #fff;
  width: 15px;
  height: 15px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-track:hover {
  background-color: #f4f4f4;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 5px solid #fff;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a5;
  border: 4px solid #f4f4f4;
}

::-webkit-scrollbar-button {
  display: none;
  .container,
  .container-fluid,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

.ant-form-item {
  margin-bottom: 10px;
}

@media screen and (max-width: 768px) {
  html {
    touch-action: manipulation !important;
  }
  
  input, textarea, select {
    font-size: 16px !important; /* Giữ kích thước font nhưng vẫn tránh việc zoom */
    -webkit-text-size-adjust: 100% !important; /* Tránh Safari phóng to */
  }

  .ant-input::placeholder {
    font-size: 14px; /* Thay đổi giá trị font-size theo ý bạn */
  }

  .ant-select-selection-placeholder {
    font-size: 14px; /* Thay đổi giá trị font-size theo ý bạn */
  }
}

.ant-modal-mask {
  z-index: 2000 !important;
}

.ant-modal-wrap {
  z-index: 2001 !important;
}
